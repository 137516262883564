<template>
    <div v-if="courses" class="wrapper wrapper-content animated fadeInRight">
      <bread-crumbs title="Сервис управления электронными образовательными ресурсами" :items="[{title: 'Главная', url: '/'}]" />
      <div class="wrapper wrapper-content">
        <b-row>
          <b-col>
            <ibox>
              <ibox-title :hide-tools="true">
                <b-row>
                  <b-col>
                    <b-modal id="add-inside-course" :hide-footer="true" title="Добавить новый курс">
                      <CourseForm/>
                    </b-modal>
                    <b-btn v-b-modal.add-inside-course class="btn btn-success btn-sm mb-1 pull-right">
                      Добавить новый курс
                    </b-btn>
                    Список курсов
                  </b-col>
                </b-row>
              </ibox-title>
              <ibox-content>
                <b-table :items="courses" :fields="courseFields">
                  <template v-slot:cell(actions)="data">
                    <b-btn-group>
                      <router-link class="btn btn-primary" :to="`/admin/course/${data.item.id}`">
                        <i class="fa fa-edit"></i>
                      </router-link>
                      <router-link class="btn btn-warning" :to="`/course/${data.item.id}`">
                        <i class="fa fa-eye"></i>
                      </router-link>
                      <router-link class="btn btn-success" :to="`/admin/stats/${data.item.id}`">
                        <i class="fa fa-area-chart"></i>
                      </router-link>
                    </b-btn-group>
                  </template>
                </b-table>
              </ibox-content>
            </ibox>
          </b-col>
          <b-col>
            <ibox>
              <ibox-title :hide-tools="true">
                <b-row>
                  <b-col>
                    <b-modal id="add-inside-post" :hide-footer="true" title="Добавить новый пост">
                      <BlogForm/>
                    </b-modal>
                    <b-modal id="edit-inside-post" :hide-footer="true" title="Редактировать пост">
                      <BlogForm :edit-post="editPost"/>
                    </b-modal>
                    <b-btn v-b-modal.add-inside-post class="btn btn-success btn-sm mb-1 pull-right">
                      Добавить пост
                    </b-btn>
                    Список постов в Блоге
                  </b-col>
                </b-row>
              </ibox-title>
              <ibox-content>
                <b-table :items="posts" :fields="[
                {
                  key: 'title',
                  label: 'Название новости'
                },
                {
                  key: 'cover',
                  label: 'Картинка'
                },
                {
                  key: 'actions',
                  label: 'Действия'
                },
              ]">
                  <template v-slot:cell(cover)="data">
                    <img v-if="data.item.cover && data.item.cover.thumbs" :src="data.item.cover.thumbs.smaller" >
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-btn-group>
                      <button class="btn btn-primary" @click="editPostFunc(data.item)">
                        <i class="fa fa-edit"></i>
                      </button>
                      <button class="btn btn-danger" @click="deletePost(data.item.id)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </b-btn-group>
                  </template>
                </b-table>
              </ibox-content>
            </ibox>
          </b-col>
        </b-row>
      </div>
    </div>
</template>

<script>
    import Ibox from "../../components/Ibox/Ibox";
    import IboxTitle from "../../components/Ibox/IboxTitle";
    import IboxContent from "../../components/Ibox/IboxContent";
    import BreadCrumbs from "../../components/BreadCrumbs";
    import ApiService from "../../services/api.service";
    import CourseForm from "./CourseForm";
    import BlogForm from "./BlogForm";
    export default {
        name: "CourseListTable",
      components: {BlogForm, CourseForm, BreadCrumbs, IboxContent, IboxTitle, Ibox},
      data() {
            return {
              posts: null,
              pagination: {
                limit: 30,
                offset: 0,
                total: 0
              },
              editPost: null,
              currentPage: 1,
              currentPostPage: 1,
              courseFields: [
                {
                  key: 'id',
                  label: 'Идентификатор курса'
                },
                {
                  key: 'name',
                  label: 'Название курса'
                },
                {
                  key: 'actions',
                  label: 'Действия'
                },
              ],
              courseName: '',
              courses: null
            }
        },
        mounted() {
          this.loadCourses()
          this.loadPosts()
        },
        methods: {
          deletePost(postId) {
            if(confirm('Вы уверены?')) {
              ApiService
                  .delete(`posts/${postId}`)
                  .then(() => {
                    this.loadPosts()
                  })
            }
          },
          editPostFunc(data) {
            this.editPost = data;
            this.$bvModal.show('edit-inside-post')
          },
          cloneCourse(courseId) {
            ApiService.postJSON('courses-clone', {course: courseId}).then(res => {
              if (res.status === 200) {
                this.loadCourses()
              }
            });
          },
          loadCourses() {
            ApiService.get('courses-list', {
              params: {
                offset: (this.currentPage-1)*this.pagination.limit,
                limit: this.pagination.limit,
                'order[id]': 'desc',
                isKids: true
              }
            }).then(res => {
              this.courses = res.data;
            });
          },
          loadPosts() {
            ApiService.get('posts', {
              params: {
                offset: (this.currentPostPage-1)*this.pagination.limit,
                limit: this.pagination.limit,
                'order[id]': 'desc',
                isKids: true
              }
            }).then(res => {
              this.posts = res.data;
            });
          }
        },
        watch: {
          courseName(val) {
            if (val && val.length > 3) {
              ApiService.get('courses-list', {
                params: {
                  hideLoader: true,
                  'filters[name]': val,
                  'filterType': 'like'
                }
              }).then(res => {
                this.courses = res.data.data;
                this.pagination = res.data.pagination;
              });
            }
          }
        },
    }
</script>

<style scoped lang="scss">
    .product-desc {
        height: 150px;
    }
    .product-imitation {
        min-height: 180px;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        span {
            color: #fff;
            z-index: 100;
        }
        .color-overlay {
            width: 100%;
            height: 100%;
            background: #000;
            opacity: .6;
            position: absolute;
        }
    }
    .product-price {
        top: -33px;
        &.finished {
            background-color: #007bff;
        }
        &.started {
            background-color: #dc3545;
        }
        &.open {
            background-color: #17a2b8;
        }
        &.soon {
            background-color: #E6A23C;
        }
        &.finished {
            background-color: #67C23A;
        }
        &.closed {
            background-color: #909399;
        }
    }
    .course-none {
        border-radius: 20px;
        font-size: 16px;
        padding: 6px;
        line-height: 22px;
        height: 33px;
        width: 33px;
        color: #fff;
        display: inline-block;
        text-align: center;
    }
</style>
