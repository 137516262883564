<template>
    <validation-observer ref="observer" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(onSubmit)">
            <b-form-group label="Картинка">
                <AvatarUpload :media="post.cover"></AvatarUpload>
            </b-form-group>
            <validation-provider name="Название" :rules="{ required: true, min: 10 }" v-slot="validationContext">
                <b-form-group
                        label="Название"
                >
                    <b-form-input
                            id="name-input"
                            v-model="post.title"
                            :state="valid(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <b-form-group>
              <valid-summernote name="описание" label="Описание" v-model="post.text" />
            </b-form-group>
            <hr class="hr-line-dashed"/>
            <b-button variant="primary" type="submit">Сохранить</b-button>
        </b-form>
    </validation-observer>
</template>

<script>
    import {eventBus as bus} from "../../main";
    import AvatarUpload from "./AvatarUpload";
    //import ValidSummernote from "../../components/Validation/ValidSummernote";
    import ApiService from "../../services/api.service";
    import ValidSummernote from "../../components/Validation/ValidSummernote";
    export default {
        name: "BlogForm",
        components: {
          ValidSummernote,
          //ValidSummernote,
          AvatarUpload
        },
        props: {
          editPost: {
            type: Object,
            required: false,
            default: null
          }
        },
        data() {
            return {
                imageUrl: null,
                post: {
                  isKids: true
                }
            }
        },
        mounted() {
            bus.$on('avatar-upload', res => {
                this.post.cover = res
            })
          if(this.editPost) {
            this.post = this.editPost
          }
        },
        methods: {
            onSubmit() {
              if(this.editPost) {
                ApiService
                    .put('posts/' + this.post.id, this.post)
                    .then(() => {
                      window.location.reload()
                    })
              } else {
                ApiService
                    .postJSON('posts', this.post)
                    .then(res => {
                      if(res.status === 201) {
                        window.location.reload()
                      }
                    })
              }
            }
        }
    }
</script>

<style>
    .upload-video .el-upload {
        display: block;
    }
</style>
